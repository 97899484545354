import { Routes, Route } from "react-router-dom";
import NavBar from "./FrontEnd/Navbar/NavBar";
import Dashboard from "./FrontEnd/Pages/Dashboard";
import LogOut from "./FrontEnd/Login/LogOut";
import Products from "./FrontEnd/Pages/AllProducts";
import Profile from "./FrontEnd/Pages/Profile";
import NotFound from "./FrontEnd/Pages/NotFount";
import UpdateProducts from "./FrontEnd/Pages/UpdateProducts";
import PostProducts from "./FrontEnd/Pages/PostProducts";
import Login from "./FrontEnd/Login/Login";
import OrderTable from "./FrontEnd/Pages/OrderTable";
import OrderDetails from "./FrontEnd/Pages/OrderDetails";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Wallet from "./FrontEnd/Pages/Wallet";
function Home() {
  const [storeProducts, setStoreProducts] = useState({});

  useEffect(() => {
    Promise.all([
      axios.get(
        `https://bardawamplus.com/api/stores/${Cookies.get("store")}/products`
      ),
      axios.get(
        `https://bardawamplus.com/api/products/pinned?store_id=${Cookies.get(
          "store"
        )}`
      ),
    ])
      .then(([productsResponse, pinnedResponse]) => {
        // Handle the responses
        const combinedData = [
          ...productsResponse.data.data,
          ...pinnedResponse.data.data,
        ];
        setStoreProducts(combinedData);

        // console.log("Combined data", combinedData);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }, []);

  return (
    <div>
      <NavBar />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/LogOut" element={<LogOut />} />
        <Route path="/Products" element={<Products />} />

        <Route path="/Profile" element={<Profile />} />
        <Route path="/Debt" element={<Wallet />} />
        <Route path="/PostProducts" element={<PostProducts />} />
        <Route path="/OrderTable" element={<OrderTable />} />
        <Route path="/OrderTable/OrderDetails/:id" element={<OrderDetails />} />
        <Route
          path="/Products/UpdateProducts/:id"
          element={<UpdateProducts storeProducts={storeProducts} />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default Home;
