import Order from "../Images/order.png";
import products from "../Images/add-product.png";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import "../Styles/Dashboard.css"; // Import the stylesheet
import { Flex, Progress, Table } from "antd";
import Cookies from "js-cookie";

const Dashboard = () => {
  const [analyticsData, setAnalyticsData] = useState(0);

  const [previousOrders, setPreviousOrders] = useState(0); // Track previous order count
  const notificationSound = useRef(null); // Ref for audio element

  useEffect(() => {
    analyticsApi();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const analyticsApi = async () => {
    try {
      const res = await axios.get(
        `https://bardawamplus.com/api/orders/store/${Cookies.get("store")}`
      );
      const newOrders = res.data.total;

      // Check if new orders have been added
      if (newOrders > previousOrders && previousOrders !== 0) {
        notificationSound.current.play(); // Play notification sound
      }

      // Update the state with new analytics data
      setAnalyticsData(res.data);
      setPreviousOrders(newOrders); // Update previous order count
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    }
  };
  //todo api for products
  const [numProducts, setNumProducts] = useState(0);
  const [PerPage, setPerPage] = useState(0);
  const GetProducts = async () => {
    try {
      const response = await fetch(
        `https://bardawamplus.com/api/stores/${Cookies.get("store")}/products`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setNumProducts(data.total);
      setPerPage(data.per_page);
    } catch (error) {
      console.error("Error fetching products:", error.message);
    }
  };
  useEffect(() => {
    GetProducts();
  }, []); // Empty dependency array to run once on mount
  //todo this is table all products
  const [dataSource, setDataSource] = useState([]);
  const getApiProducts = async () => {
    try {
      const productsRes = await fetch(
        `https://bardawamplus.com/api/stores/${Cookies.get("store")}/products`
      );
      if (!productsRes.ok) {
        throw new Error(`HTTP error! Status: ${productsRes.status}`);
      }
      const productsData = await productsRes.json();
      const combinedData = productsData.data;
      setDataSource(
        combinedData.map((item) => {
          const nameObj = JSON.parse(item.name || "{}");
          return {
            key: item.id,
            id: item.id,
            image_url: item.image_url
              ? `https://bardawamplus.com/api/storage/${item.image_url}`
              : null,
            P_S: item.pinned,
            nameKurdish: nameObj.ku || "N/A",
          };
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    getApiProducts();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "nameKurdish",
      key: "nameKurdish",
    },
    {
      title: "Image",
      dataIndex: "image_url",
      key: "image_url",
      render: (image_url) => {
        if (image_url) {
          return (
            <img
              src={image_url}
              alt="Product"
              style={{ width: "60px", height: "60px" }}
            />
          );
        } else {
          return "N/A";
        }
      },
    },
    {
      title: "products",
      dataIndex: "P_S",
      key: "pinned",
      render: (pinned) => {
        return (
          <span className={pinned === 0 ? "pinned-products" : "pinned-slider"}>
            {pinned === 0 ? "Products" : "VIP"}
          </span>
        );
      },
    },
  ];

  return (
    <div className="kk">
      <div className="dashboard-container">
        <ul className="list-container">
          <li className="list-item">
            <div className="item-content">
              <span className="icon">
                <img src={products} alt=" " height={50} width={50} />
              </span>
              <span className="user">Product</span>
              <Flex gap="small" wrap="wrap">
                <Progress
                  percent={((numProducts / PerPage) * 100).toFixed(2)}
                  status="active"
                  strokeColor={
                    (numProducts / PerPage) * 100 <= 50
                      ? "green"
                      : (numProducts / PerPage) * 100 <= 80
                      ? "yellow"
                      : "red"
                  } // green for 0-50%, yellow for 50-80%, red for 80-100%
                />
              </Flex>

              <span className="number">{numProducts}</span>
            </div>
          </li>
          <li className="list-item">
            <div className="item-content">
              <span className="icon">
                <img src={Order} alt=" " height={50} width={50} />
              </span>
              <span className="user">Orders</span>
              <span className="number">{analyticsData.total}</span>
            </div>
          </li>
        </ul>
      </div>

      <Table
        dataSource={dataSource}
        columns={columns}
        scroll={{
          y: 540,
        }}
        pagination={false}
      />
    </div>
  );
};

export default Dashboard;
