import React, { useState } from "react";
import { Form, Button, Input, message, Modal } from "antd";
import Cookies from "js-cookie";
import axios from "axios";
import { IconButton } from "@mui/material";
import { DownOutlined } from "@ant-design/icons";
import "../Styles/PostProducts.css";
const PostProducts = () => {
  const { TextArea } = Input;
  const [form1] = Form.useForm();
  const [nameArabic, setNameArabic] = useState("");
  const [nameKurdish, setNameKurdish] = useState("");
  const [price, setPrice] = useState(0);
  const [image_url, setImage_url] = useState(null);

  const [descriptionArabic, setDescriptionArabic] = useState("");
  const [descriptionKurdish, setDescriptionKurdish] = useState("");
  // const [pinned, setPinned] = useState(false);
  const [points, setPoints] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);

  //todo this is Modal  Name
  const [isModalOpenNew, setIsModalOpenNew] = useState(false);
  // const [form] = Form.useForm(); // Form instance for the outer form
  const handleCancelNew = () => {
    setIsModalOpenNew(false);
  };
  const showModalNew = () => {
    setIsModalOpenNew(true);
  };

  const handleOkNew = () => {
    setIsModalOpenNew(false);
  };
  //! End modal
  //todo this is Modal  Description
  const [isModalOpenNew2, setIsModalOpenNew2] = useState(false);
  // const [form] = Form.useForm(); // Form instance for the outer form
  const handleCancelNew2 = () => {
    setIsModalOpenNew2(false);
  };
  const showModalNew2 = () => {
    setIsModalOpenNew2(true);
  };

  const handleOkNew2 = () => {
    setIsModalOpenNew2(false);
  };
  //! End modal
  const PostApi = async () => {
    if (!image_url) {
      // Handle case where image_url is null
      message.error("Please upload an image.");
      return;
    }
    try {
      let formData = new FormData();
      formData.append("store_id", Cookies.get("store"));
      // formData.append("store_id", 5);

      formData.append(
        "name",
        JSON.stringify({
          ar: nameArabic,
          ku: nameKurdish,
        })
      );
      formData.append(
        "description",
        JSON.stringify({
          ar: descriptionArabic,
          ku: descriptionKurdish,
        })
      );
      formData.append("price", price);
      formData.append("image_url", image_url);

      // console.log(image_url);
      formData.append("discounted_price", discountedPrice);
      formData.append("points", points);
      // formData.append("pinned", pinned ? 1 : 0);
      // Log formData content
      // console.log("FormData:", formData);
      // eslint-disable-next-line no-unused-vars
      for (const pair of formData.entries()) {
        // console.log(pair[0], pair[1]);
      }
      // Route::post('/products/pstpdbdps', [ProductController::class, 'store']);
      // const res = await fetch("https://bardawamplus.com/api/products", {
      const res = await fetch(
        "https://bardawamplus.com/api/products/pstpdbdps",
        {
          method: "POST",
          body: formData,
        }
      );
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      // eslint-disable-next-line no-unused-vars
      const data = await res.json();
      // console.log("Response:", data);
      // Handle success message here
      message.success("Form submitted successfully");
      window.location.href = "/";
    } catch (error) {
      console.error("Error:", error);
      // Handle error message here
      message.error("Failed to submit form. Please try again later.");
    }
  };
  //todo ---------------------------------------------------------------------
  const onFinish = (values) => {
    // console.log("Success:", values);
    // message.success("Success Post ");
  };
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
    message.error("Error");
  };
  const handleResetCart = () => {
    form1.resetFields();
  };
  //todo  -----------------------------------------------
  //todo  ----------------------------------------------
  //todo this is Image========================================
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const handleImageChange = async (file) => {
    // Generate a preview URL for the image file
    const previewUrl = URL.createObjectURL(file);
    setImagePreviewUrl(previewUrl);

    // Proceed with the upload logic
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(
      "https://bardawamplus.com/api/upload-file",
      formData
    );

    if (response.status === 200) {
      const imageUrl = response.data.path;
      setImage_url(imageUrl);
      // message.success("Image uploaded successfully");

      // Revoke the preview URL after upload is successful
      URL.revokeObjectURL(previewUrl);
    } else {
      message.error("Failed to upload image");
    }
  };
  const normFile = (e) => {
    return e && e.fileList ? [e.file] : [];
  };
  const onChangeUpload = async (e) => {
    if (e && e.target && e.target.files) {
      const file = e.target.files[0];
      await handleImageChange(file);
    }
  };
  //todo  -----------------------------------------------

  //todo  -----------------------------------------------
  //todo  -----------------------------------------------
  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16,
    },
  };
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1 style={{ fontSize: "24px", marginBottom: "20px" }}>Add Product</h1>
      <Form
        form={form1}
        {...layout}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {/* //todo this is image  */}
        <div className="centered-form-item">
          <Form.Item
            name="image-url"
            // label={"image"}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                type: "text",
                required: true,
                message: "Image is invalid",
              },
            ]}
          >
            <input
              id="single-image-upload"
              type="file"
              style={{ display: "none" }}
              onChange={onChangeUpload}
            />
            <label htmlFor="single-image-upload">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                className="upload-button"
              >
                AddImage
              </IconButton>
            </label>
            {imagePreviewUrl ? (
              <img
                src={imagePreviewUrl}
                alt="Preview of the selected file"
                className="image-preview"
              />
            ) : image_url ? (
              <img
                src={image_url}
                alt="Uploaded file preview"
                className="image-preview"
              />
            ) : null}
          </Form.Item>
        </div>
        {/* //! end image ================================= */}

        {/* //todo this is name  */}
        <div className="NameAdders">
          <Form.Item name="name">
            <Button type="text" className="ButtonName" onClick={showModalNew}>
              <div className="OnButton">
                Name
                <DownOutlined />
              </div>
            </Button>

            <Modal
              title="Name"
              open={isModalOpenNew}
              onOk={handleOkNew}
              onCancel={handleCancelNew}
              footer={
                <div>
                  <Button
                    type="primary"
                    onClick={handleOkNew}
                    style={{
                      width: "30%",
                    }}
                  >
                    save
                  </Button>
                  <span> </span>
                  <Button onClick={handleCancelNew}>cancel</Button>
                </div>
              }
            >
              <Form
                // form={form}

                onFinishFailed={onFinishFailed}
                initialValues={{ remember: true }}
              >
                <Form.Item name="TitleKurdish" label="Kurdish">
                  <Input
                    placeholder="Type your Title Kurdish"
                    value={nameKurdish}
                    onChange={(e) => setNameKurdish(e.target.value)}
                  />
                </Form.Item>
                <Form.Item name="TitleArabic" label="Arabic">
                  <Input
                    placeholder="Type your Title Arabic"
                    value={nameArabic}
                    onChange={(e) => setNameArabic(e.target.value)}
                  />
                </Form.Item>
              </Form>
            </Modal>
          </Form.Item>
        </div>
        {/* //todo ----------------------------------------------- */}

        <Form.Item name="Price" label="Price">
          <Input
            style={{ width: "40%", paddingLeft: 20 }}
            placeholder="Type your Price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </Form.Item>
        <Form.Item name="Discount" label="Discount">
          <Input
            style={{ width: "40%", paddingLeft: 20 }}
            placeholder="Type your discount"
            value={discountedPrice}
            onChange={(e) => setDiscountedPrice(e.target.value)}
          />
        </Form.Item>
        <Form.Item name="points" label="points">
          <Input
            style={{ width: "40%", paddingLeft: 20 }}
            placeholder="Type your points"
            value={points}
            onChange={(e) => setPoints(e.target.value)}
          />
        </Form.Item>
        {/* //todo this is description */}
        <div className="NameAdders">
          <Form.Item name="name">
            <Button type="text" className="ButtonName" onClick={showModalNew2}>
              <div className="OnButton">
                Description
                <DownOutlined />
              </div>
            </Button>

            <Modal
              title="Description"
              open={isModalOpenNew2}
              onOk={handleOkNew2}
              onCancel={handleCancelNew2}
              footer={
                <div>
                  <Button
                    type="primary"
                    onClick={handleOkNew2}
                    style={{
                      width: "30%",
                    }}
                  >
                    save
                  </Button>
                  <span> </span>
                  <Button onClick={handleCancelNew2}>cancel</Button>
                </div>
              }
            >
              <Form
                // form={form}

                onFinishFailed={onFinishFailed}
                initialValues={{ remember: true }}
              >
                <Form.Item
                  name="DescriptionKurdish"
                  label="Description Kurdish"
                >
                  <TextArea
                    showCount
                    maxLength={250}
                    // onChange={onChange}
                    style={{
                      height: 65,
                      resize: "none",
                    }}
                    value={descriptionKurdish}
                    onChange={(e) => setDescriptionKurdish(e.target.value)}
                    placeholder="Type your Title Kurdish"
                  />
                </Form.Item>
                <Form.Item name="DescriptionArabic" label="Description Arabic">
                  <TextArea
                    showCount
                    maxLength={250}
                    // onChange={onChange}
                    style={{
                      height: 65,
                      resize: "none",
                    }}
                    placeholder="Type your Title Arabic"
                    value={descriptionArabic}
                    onChange={(e) => setDescriptionArabic(e.target.value)}
                  />
                </Form.Item>
              </Form>
            </Modal>
          </Form.Item>
        </div>
        {/* //! ----------------------------------------------- */}

        {/* <Form.Item>
          <Checkbox checked={pinned} onChange={onChangeCheckbox}>
            Add Slider
          </Checkbox>
        </Form.Item> */}
        <Form.Item label=".">
          <Button type="primary" htmlType="submit" onClick={PostApi}>
            Submit
          </Button>
          <span style={{ margin: "0 8px" }}></span>
          <Button type="primary" danger onClick={handleResetCart}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PostProducts;
