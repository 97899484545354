import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "../Styles/OrderDetails.css";
import { List, Spin } from "antd";
import Cookies from "js-cookie";

const OrderDetails = () => {
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const storeId = Cookies.get("store");

  useEffect(() => {
    axios
      .get(`https://bardawamplus.com/api/orders/${id}`)
      .then((response) => {
        setOrderDetails(response.data);
      })
      .catch((error) => {
        console.error("Error fetching order details:", error);
      });
  }, [id]);

  if (!orderDetails) {
    return (
      <div>
        <Spin tip="Loading...">
          <div style={{ width: "100%", height: "100vh" }} />
        </Spin>
      </div>
    );
  }

  // Filter products for the current store
  const storeProducts = orderDetails.products.filter(
    (product) => product.store_id === parseInt(storeId)
  );

  // Recalculate subtotal based on filtered products
  const recalculatedSubAmount = storeProducts.reduce(
    (total, product) => total + product.discounted_price * product.quantity,
    0
  );

  return (
    <div>
      <div className="order-details-container">
        <h2 className="order-details-title">Order Details</h2>
        <div className="order-details-content">
          <p>
            <span className="detail-label">Username:</span>{" "}
            {orderDetails.username}
          </p>
          <p>
            <span className="detail-label">Subtotal:</span>{" "}
            {recalculatedSubAmount}
          </p>
          <p>
            <span className="detail-label">Delivery Fee (in Erbil):</span> 3000
          </p>
          <p>
            <span className="detail-label">Total (in Erbil):</span>{" "}
            {recalculatedSubAmount + 3000}
          </p>
          <p>
            <span className="detail-label">Delivery Fee (in Kurdistan):</span>{" "}
            5000
          </p>
          <p>
            <span className="detail-label">Total (in Kurdistan):</span>{" "}
            {recalculatedSubAmount + 5000}
          </p>
          <p>
            <span className="detail-label">Delivery Fee (out Kurdistan):</span>{" "}
            8000
          </p>
          <p>
            <span className="detail-label">Total (out Kurdistan):</span>{" "}
            {recalculatedSubAmount + 8000}
          </p>
          <p>
            <span className="detail-label">Phone Number:</span>{" "}
            {orderDetails.phone_number}
          </p>
          <p>
            <span className="detail-label">Address:</span>{" "}
            {orderDetails.address}
          </p>
          <p>
            <span className="detail-label">Note:</span> {orderDetails.note}
          </p>

          <List>
            <hr
              style={{
                border: "none",
                borderWidth: "bold",
                borderTop: "1px solid #aaaaaa",
                margin: "23px 0",
              }}
            />
            {storeProducts.map((product, index) => (
              <List.Item key={index}>
                <span className="detail-label"></span>
                <div className="product-info">
                  <strong>Product Name:</strong>{" "}
                  {renderProductName(product.name)}
                  <br />
                  <strong>Price:</strong> {product.discounted_price}
                  <br />
                  <strong>Quantity:</strong> {product.quantity}
                  <br />
                  <strong>Points:</strong> {product.points}
                </div>
                <img
                  height={150}
                  width={150}
                  src={`https://bardawamplus.com/api/storage/${product.image_url}`}
                  alt={renderProductName(product.name)} // Use product name for alt text
                />
              </List.Item>
            ))}
          </List>
        </div>
      </div>
    </div>
  );

  function renderProductName(nameJSON) {
    try {
      const parsedName = JSON.parse(nameJSON);
      const productName = parsedName.ku || parsedName.ar || "Unnamed Product";
      return <span>{productName}</span>;
    } catch (error) {
      console.error("Error parsing product name JSON:", error);
      return <span>Error parsing product name</span>;
    }
  }
};

export default OrderDetails;
