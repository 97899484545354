import { useState } from "react";
import { Button, Form, Input, message } from "antd";
import { MailOutlined, LockOutlined, LoginOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import Logo from "../Images/favicon.jpg";
import "../Styles/Login.css";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleLogin = () => {
    const URI = "https://bardawamplus.com/api/login-store";

    const requestBody = {
      email,
      password,
    };
    // console.log("first", requestBody);
    fetch(URI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((error) => {
            throw new Error(
              `HTTP error! Status: ${res.status}, Message: ${error.message}`
            );
          });
        }
        return res.json();
      })
      .then((data) => {
        Cookies.set("user", JSON.stringify(data["user"]["id"]));
        Cookies.set("store", JSON.stringify(data["store"]["id"]));

        window.location.href = "/";
        message.success("Login successful");
      })
      .catch((error) => {
        message.error("Login failed");
      });
  };

  const onFinish = (values) => {
    // console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
    // message.error("Error");
  };

  return (
    <div className="login-container">
      {/* Add the header image */}

      <div className="login-header">
        <img src={Logo} alt="Login Header" className="header-image" />
      </div>
      <div className="login-form-container">
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name={"email"}
            label={<span className="form-label">Email</span>}
            rules={[
              {
                type: "email",
                required: true,
                message: "Email is invalid",
              },
            ]}
          >
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              prefix={<MailOutlined />}
            />
          </Form.Item>

          <Form.Item
            label={<span className="form-label">Password</span>}
            name="password"
            rules={[
              {
                required: true,
                message: "Password is invalid",
              },
            ]}
          >
            <Input.Password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              prefix={<LockOutlined />}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: "center" }}>
            <Button
              type="primary"
              htmlType="submit"
              icon={<LoginOutlined />}
              onClick={handleLogin}
              style={{ width: "70%" }} // Optional: Adjust button width
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default Login;
