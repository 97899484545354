import "../Styles/Profile.css";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const Wallet = () => {
  // eslint-disable-next-line no-unused-vars
  const [store, setStore] = useState({});

  const [balance, setBalance] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch store data
        const storeResponse = await axios.get(
          `https://bardawamplus.com/api/stores/${Cookies.get("store")}`
        );
        const storeData = storeResponse.data;
        setStore(storeData);

        // Fetch owner balance
        if (storeData.owner_id) {
          const userResponse = await fetch(
            `https://bardawamplus.com/api/users/${storeData.owner_id}`
          );
          const userData = await userResponse.json();
          setBalance(userData.balance || 0);
        }
      } catch (error) {
        console.error("Error fetching store data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <div style={{ marginTop: "20px" }}>
        <h3
          style={{
            fontSize: "1.8rem",
            fontWeight: "bold",
            color: "black", // Red color for emphasis
            backgroundColor: "#f5f5f5", // Light grey background
            padding: "15px",
            borderRadius: "8px",
            border: "1px solid #ddd",
            display: "inline-block",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Light shadow for floating effect
            marginTop: "20px",
          }}
        >
          Your store currently owes{" "}
          <span
            style={{
              color: "green", // Blue color for balance emphasis
              fontSize: "2rem", // Larger font size for the balance
            }}
          >
            {balance}
          </span>{" "}
          IQD
        </h3>
      </div>
    </div>
  );
};

export default Wallet;
