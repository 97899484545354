import React from "react";
import { Dropdown, Menu, Button } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import Cookies from "js-cookie";
const MenuTop = () => {
  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<UserOutlined />}>
        <Link to="/profile">profile</Link>
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<LogoutOutlined />}
        danger
        onClick={() => {
          // localStorage.removeItem("sessionToken");
          Cookies.remove("user");
          Cookies.remove("store");
          window.location.href = "/";
        }}
      >
        logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" arrow>
      <Button>Menu</Button>
    </Dropdown>
  );
};

export default MenuTop;
