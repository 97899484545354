import {
  EditOutlined,
  ArrowUpOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { Table, Button, message, Modal, Input } from "antd";
import Cookies from "js-cookie";
import { animateScroll as scroll } from "react-scroll";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../Styles/Table.css";
const Products = () => {
  //todo -----------------Search-----------
  const [SearchText, setSearchText] = useState("");
  //todo----------------------------------------------
  const [dataSource, setDataSource] = useState([]);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  useEffect(() => {
    getApiProducts();
  }, []);

  useEffect(() => {
    getApiProducts();
  }, []);

  const getApiProducts = async () => {
    try {
      // Fetch only the products
      const productsRes = await fetch(
        `https://bardawamplus.com/api/stores/${Cookies.get("store")}/products`
      );

      // Check if the response is OK
      if (!productsRes.ok) {
        throw new Error(`HTTP error! Status: ${productsRes.status}`);
      }

      // Parse the JSON data
      const productsData = await productsRes.json();

      // Map and process the product data
      setDataSource(
        productsData.data.map((item) => {
          const nameObj = JSON.parse(item.name);
          const descriptionObj = JSON.parse(item.description);

          return {
            id: item.id,
            nameKurdish: nameObj.ku || "N/A",
            nameArabic: nameObj.ar || "N/A",
            Price: item.price,
            image_url: item.image_url
              ? `https://bardawamplus.com/api/storage/${item.image_url}`
              : null,
            discounted_price: item.discounted_price,
            detailedKU: descriptionObj.ku || "N/A",
            detailedAr: descriptionObj.ar || "N/A",
            points: item.points,
            P_S: item.pinned,
          };
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //todo remove item
  const handleDelete = (id) => {
    setDeleteItemId(id);
    setIsDeleteModalVisible(true);
  };
  const handleConfirmedDelete = async () => {
    try {
      // Route::delete('/products/{id}/dppddbps', [ProductController::class, 'delete']);
      // await fetch(`https://bardawamplus.com/api/products/${deleteItemId}`, {
      await fetch(
        `https://bardawamplus.com/api/products/${deleteItemId}/dppddbps`,
        {
          method: "DELETE",
        }
      );

      message.success("Product deleted successfully");
      // console.log("Product deleted successfully");
      // eslint-disable-next-line no-unused-vars
      const updatedData = await getApiProducts();
      // console.log("Updated Data:", updatedData);

      setDeleteItemId(null);
      setIsDeleteModalVisible(false);
    } catch (error) {
      console.error("Error deleting product:", error);
      message.error("Error deleting product");
    }
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      filteredValue: [SearchText],
      onFilter: (value, record) => {
        return (
          String(record.nameKurdish)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.nameArabic)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.id).toLowerCase().includes(value.toLowerCase())
        );
      },
    },

    {
      title: "image_url",
      dataIndex: "image_url",
      key: "image_url",
      render: (item) => {
        // console.log("Image URL:", item);
        if (item) {
          return (
            <img
              src={item}
              alt="img"
              style={{ width: "50px", height: "50px", objectFit: "cover" }}
            />
          );
        } else {
          return "N/A";
        }
      },
    },

    {
      title: "Delete",
      key: "action",
      render: (text, record) => (
        <div>
          {/* <Button type="primary" onClick={() => handleUpdate(record)}>
            Update
          </Button> */}

          <span> </span>
          <Button
            type="danger"
            style={{ background: "red", border: "red", color: "white" }}
            onClick={() => handleDelete(record.id)}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
    {
      title: "Edit",
      dataIndex: "Action",
      key: "action",
      render: (text, record) => (
        <Link to={`/Products/UpdateProducts/${record.id}`}>
          {text}
          <Button type="primary" icon={<EditOutlined />}></Button>
        </Link>
      ),
    },
  ];
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div>
      <Input.Search
        placeholder="Search here ...."
        style={{
          marginBottom: 15,
          marginTop: 12,
          width: 300,
        }}
        onSearch={(value) => {
          setSearchText(value);
        }}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
      <Button
        type="primary"
        className="scroll-to-top-button"
        onClick={scrollToTop}
        // icon={<ArrowUpOutlined />}
      >
        <ArrowUpOutlined />
      </Button>
      <Link to="/PostProducts">
        <Button
          type="primary"
          className="navigate-to-new-page-button"
          // icon={<PlusOutlined />}
        >
          <PlusOutlined />
        </Button>
      </Link>
      <Table
        dataSource={dataSource.map((item) => ({ ...item, key: item.id }))}
        columns={columns}
        pagination={false}
      />

      {/* Delete Modal */}
      <Modal
        title="Confirm Deletion"
        open={isDeleteModalVisible}
        onOk={handleConfirmedDelete}
        onCancel={() => setIsDeleteModalVisible(false)}
      >
        <p>Are you sure you want to delete this product?</p>
      </Modal>
    </div>
  );
};

export default Products;
